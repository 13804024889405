import { Link } from "gatsby"
import React from "react"
import { IMenuItem } from "../Menu";

export interface IMenuListingProps
{
    menuItems: Array<IMenuItem>;
    limit?: number;
}

export class MenuListing extends React.Component<IMenuListingProps, {}>
{
    private _limit: number = this.props.menuItems.length;
    constructor(props: IMenuListingProps)
    {
        super(props);

        if (this.props.limit && this.props.limit <= this.props.menuItems.length)
        {
            this._limit = this.props.limit;
        }
    }

    private buildMenuItems(): Array<JSX.Element>
    {
        var menuItems = [];

        for (var i = 0; i < this._limit; i++)
        {
            var menuItem = this.props.menuItems[i];
            menuItems.push(
                <div key={i} className="menu-item">
                    <h3>{menuItem.name}</h3>
                    {
                        menuItem.description.length > 1 ?
                            <p>
                                {menuItem.description}
                            </p>
                            : null
                    }
                </div>
            );
        }

        return menuItems;
    }

    public render(): JSX.Element
    {
        return (
            <div className="menu-items">
                {this.buildMenuItems()}
            </div>
        );
    }
}