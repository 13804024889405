import React from "react"
import { Layout, ILayoutProps } from "../components/Layout"
import { SEO } from "../components/Seo"
import { graphql, Link } from "gatsby";
import { MenuListing } from "../components/MenuListing";
import { IAppProps } from ".";

export const query = graphql`
query TraditionalMenuQuery {
    traditionalJson {
        items {
            id
            name
            description
        }
    }
    metaImage: file(relativePath: {eq: "meta.jpg"}) {
    childImageSharp {
      original{src
      }
    }
  }
}
`;

export default class TraditionalMenusPage extends React.Component<IAppProps, {}>
{
    constructor(props: IAppProps)
    {
        super(props);
    }

    public render(): JSX.Element
    {
        return (
            <Layout className="traditional">
                <SEO title="Traditional Chinese Menu" image={this.props.data.metaImage.childImageSharp.original.src} />
                <section className="hero hero-sub-page">
                    <div className="hero-content text-center">
                        <div className="content content-right">
                            <h1>Traditional Menu<br /></h1>
                            <p className="lead">
                                Traditional Chinese cuisine at its best.
                            </p>

                            <div className="button-group" style={{ width: "600px", maxWidth: "100%", margin: "0 auto" }}>
                                <Link to="/book" title="Book a table" className="btn btn-solid">
                                    Book a table
							    </Link>
                                <Link to="/menus" title="All menus" className="btn btn-solid">
                                    All menus
								</Link>
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <div className="content">
                        <div className="menu">
                            <div className="menu-feature">
                                <h1>Traditional  <span>Popular Dishes</span></h1>
                                <div className="feature-text">
                                    <p>
                                        These are just a few of our most popular dishes, <br/>
                                        our full traditional menu is available in the restaurant.
                                    </p>
                                </div>
                            </div>
                            <MenuListing menuItems={this.props.data.traditionalJson.items} />
                        </div>
                    </div>
                </section>
            </Layout>
        );
    }
}